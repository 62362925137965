import * as React from "react"
import styled from "styled-components";
import Simple from "@templates/Simple"
import { Link } from "gatsby";
import p1 from "@images/info/p1.jpg";
import p2 from "@images/info/p2.jpg";
import p3 from "@images/info/p3.jpg";
import p4 from "@images/info/p4.jpeg";
import p5 from "@images/info/p5.jpeg";
import p6 from "@images/info/p6.jpeg";
import p7 from "@images/info/p7.jpeg";
import p8 from "@images/info/p8.jpeg";
import p9 from "@images/info/p9.jpeg";
import p10 from "@images/info/p10.jpeg";
import p11 from "@images/info/p11.jpeg";
import p12 from "@images/info/p12.jpeg";
import p13 from "@images/info/p13.jpeg";
import p14 from "@images/info/p14.jpeg";
import p15 from "@images/info/p15.jpeg";
import p16 from "@images/info/p16.jpeg";
import p18 from "@images/info/p18.jpg";
import p19 from "@images/info/p19.jpeg";
import p20 from "@images/info/p20.jpeg";
import p21 from "@images/info/p21.jpg";


const Page = () => {
  return (
    <Simple title={"CRI: Información para la certificación"}>
      <Wrapper>
        <h1>Información para la certificación por CONCYTEC</h1>

        Para la certificación por CONCYTEC, debe de ingresar con su usuario y clave del CTI Vitae, mas información <a href={"https://sites.google.com/a/concytec.gob.pe/manual-dina/cri"} target={"_blank"} rel={"noreferrer"}> aquí</a>.

        <h1>Información para la certificación por la UPCH</h1>

        <ol>
          <li>Leer los <Link to={"/contenidos"} >contenidos</Link></li>
          <li>Ingresar a este <a href={"https://cri.andeanquipu.org/moodle/"} target={"_blank"} rel={"noreferrer"}  >enlace</a></li>
          <li>
            Aparecerá el siguiente portal
            <br />
            <img src={p1} alt={p1} />
          </li>
          <li>
            Hagá clic en el nombre del curso
            <br />
            <img src={p2} alt={p2} />
          </li>
          <li>
            Aparecerá el siguiente formulario.
            <ul>
              <li>
                Para registrarse: coloque un nombre de usuario y una contraseña y haga clic en Acceder
              </li>
              <li>
                Para ingresar: coloque su nombre de usuario/correo que con el que se registro, su contraseña y haga clic en acceder
              </li>
              <li>
                Si olvido su contraseña, haga clic en el enlace de la derecha, aparecera dos formularios donde  le podran enviar un enlace de cambio de contraseña al correo si coloca su usuario o correo.
              </li>
            </ul>
            <br />
            <img src={p3} alt={p3} />
          </li>
          <li>
            Luego de ingresar ya sea con una nueva cuenta o no, se le pedirá actualizar/completar sus datos. Complete y presione el botón Actualizar/Guardar.
            <br />
            <img src={p4} alt={p4} />
          </li>
          <li>
            Luego, debrá hacer clic en "Inicio del Sitio".
            <br />
            <img src={p5} alt={p5} />
          </li>
          <li>
            Ahora haga clic en el nombre del curso. (Si ya ha ingresado antes, salte al paso 10)
            <br />
            <img src={p6} alt={p6} />
          </li>
          <li>
            Ahora haga clic en "Matricularme".
            <br />
            <img src={p7} alt={p7} />
          </li>
          <li>
            Ahora haga clic en "Evaluación CRI"
            <br />
            ( Si ya hubiera obtenido un puntaje aprobatorio, "Certificado CRI" estara desbloqueado.
            <br />
            Haga clic y salte al paso 18).
            <br />
            <img src={p8} alt={p8} />
          </li>
          <li>
            Ahora haga clic en "Intente resolver el cuestionario ahora".
            <br />
            <img src={p9} alt={p9} />
          </li>
          <li>
            Luego haga clic en comenzar intento.
            <br />
            <img src={p10} alt={p10} />
          </li>
          <li>
            Resuelva todas las preguntas. Después de marcar, haga clic en siguiente página. (Tiene intentos ilimitados, no se preocupe)
            <br />
            <img src={p11} alt={p11} />
          </li>
          <li>
            Al finalizar todas las preguntas, haga clic en enviar todo y continuar.
            <br />
            <img src={p12} alt={p12} />
          </li>
          <li>
            Podrá ver todas las preguntas en que se equivoco y su calificación. Haga clic en  finalizar revisión.
            <br />
            <img src={p13} alt={p13} />
          </li>
          <li>
            Luego de aprobar podrá pasar a acceder a su certificado
            <br />
            <img src={p14} alt={p14} />
          </li>
          <li>
            Tambien puede acceder a su certificado desde el contenido del curso si ya tiene la nota aprobatoria. Haga clic en "Certificado CRI"
            <br />
            <img src={p16} alt={p16} />
          </li>
          <li>
            Ahora haga clic en obtener certificado
            <br />
            <img src={p15} alt={p15} />
          </li>
          <li>
            Se le abrira una ventana adicional con el certifica que podra descargar si desea.
            <br />
            <img src={p18} alt={p18} />
          </li>
          <li>
            Si alguien desease comprobar la veracidad del certificado puede usar el lector QR o acceder a esta <a href={"https://cri.andeanquipu.org/moodle/mod/simplecertificate/verify.php"} target={"_blank"} rel={"noreferrer"}> dirección </a> y colocal el codigo que aparece debajo del codigo QR.
            <br />
            <img src={p19} alt={p19} />
          </li>
          <li>
            Podrá observar los datos de quien dio la prueba.
            <br />
            <img src={p20} alt={p20} />
          </li>
          <li>
            Podrá descargar una copia del certificado, de ser necesario.
            <br />
            <img src={p21} alt={p21} />
          </li>
          <li>
            Eso seria todo
            <br />
            Exitos!

          </li>
        </ol>
      </Wrapper>
    </Simple>
  )
}

export default Page;

const Wrapper = styled.div`
padding: 1em 2em;
margin-bottom: 5em;

color: var(--color4);
h1{
  font-size:29px;
  padding: 15px 0;
}
li{
  font-size:16px;
  padding: 5px 0;
}
a{
  color:var(--color1);
  :hover{
    color:var(--color1);
  }
}

img{
  border: solid 5px var(--color1);
  margin: 10px auto;
    width : 100%;
    
  }
`